import { useMutation } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const detectLanguage = async (postData) => {
  return http().post(endpoints.forms.aiTools, postData);
};

export function useLanguageIdentifier() {
  return useMutation(detectLanguage, {
    onError: (error) => {
      const errorMessage =
        error.message || "An error occurred while detecting the language.";
      // alert(errorMessage);
    },
  });
}
// import { useMutation } from "react-query";

// const detectLanguage = async (postData) => {
//   const response = await fetch(
//     "https://api.machinetranslation.com/v1/detect/language",
//     {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(postData),
//     }
//   );

//   if (!response.ok) {
//     const errorData = await response.json();
//     throw new Error(errorData.message || "Failed to detect language");
//   }

//   const responseData = await response.json();
//   console.log("Language detected successfully!");
//   return responseData;
// };

// export function useLanguageIdentifier() {
//   return useMutation(detectLanguage, {
//     onError: (error) => {
//       const errorMessage =
//         error.message || "An error occurred while detecting the language.";
//       alert(errorMessage);
//     },
//   });
// }
